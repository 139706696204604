@media screen and (min-width: 480px) and (orientation: landscape) {
  .autoOreintations {
    display: flex;
    flex-direction: row;
    background-color: black;
    background-image: url("../assets/images/Verticle_Error.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    pointer-events: auto;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    min-height: 100vh;
    z-index: 9999999999;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .inner-panel {
    display: none;
  }
}

@media (orientation: portrait) {
  .autoOreintations {
    flex-direction: row;
  }
}
.overite {
  display: grid;
  height: 100%;
}
